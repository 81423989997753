import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import BlogCategories from '../../../components/BlogCategories';

const UtilitiesEasements = () => (
  <Layout
    title="Articles about surveying and RPR (Real Property Report) requirements relating to municipal bylaws permits and related issues."
    description="Want to learn more about the bylaws in Calgary, Red Deer and Edmonton and how they effect an RPR / Real Property Reports? This a in depth blog style resource to help you understand the details of the many permit and by-law issues."
    keywords=""
  >
    <main>
      <BlogCategories />

      <h1>Category: Utilities & Easements</h1>
      <ul>
        <li>
          <Link to="/articles/gas-coop-utility-right-of-ways-rural-properties">
            Gas CO-OP and Utility Right of Way for Rural Properties
          </Link>
          <br />
          In rural Alberta, the installation and management of natural gas infrastructure differ
          significantly from urban areas. Urban regions employ Utility Right Of Way Plans granting
          specific rights and obligations related to the infrastructure's location, outlined in on
          Real Property Reports (R.P.R.).
        </li>
        <li>
          <Link to="/articles/utility-right-of-ways">Utility Right of Ways</Link>
          <br />
          Utility Right of Ways are critical yet often overlooked aspects of property ownership and
          real estate transactions. These designated portions of land, owned by one property owner
          but granting access rights to another, are common found throughout Alberta.
        </li>
      </ul>
    </main>
  </Layout>
);

export default UtilitiesEasements;
